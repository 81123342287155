//request.js
import axios from 'axios'

const request = axios.create({
  baseURL: window.apiUrl,
  timeout: 5000

})

// 请求拦截器
request.interceptors.request.use((config) => {

  // console.log('正在加载....')
  return config
}, (error) => {
  return Promise.reject(error)
})

// 响应拦截器 --- 所有请求的相应先到此处
request.interceptors.response.use((response) => {
  let code = response.status;
  if(code===200){
    return response.data;
  }
  // console.log('加载完毕')
  return response
}, (error) => {
  return Promise.reject(error)
})
export default request
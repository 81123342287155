import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)


/**
 * 存放 ** 数据
 * **/

const store = new Vuex.Store({
  state: {
    //存放的键值对就是所要管理的状态
    msg: []
  },
  mutations: {
    getMsg(state) {
      state.msg
    }
  }
})

export default store
import request from './request'

//获取菜单列表
export function getMenuList(params) {
    return request({
        url: '/api/yymenu/list',
        method: 'get',
        params: params
    })
}

//获取菜单对象
export function getMenuInfo(id) {
    return request({
        url: '/api/yymenu/'+id,
        method: 'get'
    })
}

//logo
export function getLogo() {
    return request({
        url: '/api/config/logo',
        method: 'get'
    })
}
//contact
export function getContact() {
    return request({
        url: '/api/config/contact',
        method: 'get'
    })
}
//qrcode
export function getQrcode() {
    return request({
        url: '/api/config/qrcode',
        method: 'get'
    })
}

//获取内容信息列表
export function getContentList(params) {
    return request({
        url: '/api/content/list',
        method: 'get',
        params: params
    })
}
//获取内容信息列表
export function getContentInfo(id) {
    return request({
        url: '/api/content/'+id,
        method: 'get'
    })
}
import Vue from 'vue'
import Router from 'vue-router'
import Index from '../view/index.vue';
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      component: Index,
      redirect: '/home',
      children: [
        {
          path: '/home',
          name: '首页',
          component: (resolve) => require(['@/view/home'], resolve),
        },
        {
          path: '/production',
          name: '产品中心',
          component: (resolve) => require(['@/view/business'], resolve),
        },
        {
          path: '/business',
          name: '业务中心',
          component: (resolve) => require(['@/view/business'], resolve),
        },
        {
          path: '/logistics',
          name: '物流金融',
          component: (resolve) => require(['@/view/business'], resolve),
        },
        {
          path: '/aftermarket',
          name: '汽车后市场',
          component: (resolve) => require(['@/view/business'], resolve),
        },
        {
          path: '/supply',
          name: '货源信息',
          component: (resolve) => require(['@/view/supply'], resolve),
        },
        {
          path: '/carinfo',
          name: '车源信息',
          component: (resolve) => require(['@/view/carinfo'], resolve),
        },
        {
          path: '/news',
          name: '新闻资讯',
          component: (resolve) => require(['@/view/news'], resolve),
        },
        {
          path: '/news/detail',
          name: '新闻正文',
          component: (resolve) => require(['@/view/news-details'], resolve),
        },
        {
          path: '/about',
          name: '关于我们',
          component: (resolve) => require(['@/view/business'], resolve),
        },
        {
          path: '/sysRegister',
          name: '经纪人注册',
          component: (resolve) => require(['@/view/sysRegister'], resolve)
        },
        {
          path: '/supply/detail/:id',
          name: '货源详情',
          component: (resolve) => require(['@/view/supply-details'], resolve)
        },
        {
          path: '/carinfo/detail/:id',
          name: '车源详情',
          component: (resolve) => require(['@/view/carinfo-details'], resolve)
        },
        {
          path: '/notice',
          name: '公告信息',
          component: (resolve) => require(['@/view/noticeinfo'], resolve),
        }
      ]
    }
  ]
})